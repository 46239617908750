import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Progress } from '@features/files-upload/interface/progress.interface';
import { filter, map, Observable, OperatorFunction } from 'rxjs';

export const UPLOAD_ERROR_MESSAGE = 'Upload Error';

export function uploadProgress<T>(): OperatorFunction<HttpEvent<unknown>, Progress | T> {
  return (source: Observable<HttpEvent<unknown>>): Observable<Progress | T> =>
    source.pipe(
      map(event => event as HttpEvent<T>),
      map((event: HttpEvent<T>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return { progress: 0, total: -1 } as Progress;
          case HttpEventType.UploadProgress:
            return { progress: event.loaded, total: event.total ?? -1 };
          case HttpEventType.Response:
            if (event.status < 200 || event.status >= 300) {
              throw new HttpErrorResponse({
                error: UPLOAD_ERROR_MESSAGE,
                status: event.status,
                statusText: event.statusText,
              });
            } else {
              return event.body as T;
            }
          default:
            return null;
        }
      }),
      filter((r: Progress | T | null) => r !== null),
      map(r => r as Progress | T) // above filter is not enough for eslint
    );
}
