import { Injectable } from '@angular/core';
import { DataType, HttpWrapperService, IndexedDbRowId, makeBinaryField } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { Observable } from 'rxjs';

import { SignalMetadata } from '../interface/signal-metadata';
@Injectable({
  providedIn: 'root',
})
export class SignalOverviewApiService {
  constructor(private api: HttpWrapperService) {}

  getSignalBytes(signalId: AStrionSignalId, contentPath: string, metadata: SignalMetadata): Observable<IndexedDbRowId> {
    return this.api.get<IndexedDbRowId>(
      `${environment.BASE_API_URL}/operation-management/signals/data-proxy/${contentPath}`,
      {
        storage: makeBinaryField({
          id: signalId,
          dbName: ASTRION_INDEXEDDB_NAME,
          storeName: ASTRION_INDEXEDDB_TABLES.signals,
          dstName: 'samples',
          dataType: DataType.Float64,
          additionalFields: {
            xMin: 0.0,
            samplingFrequency: metadata.samplingFrequency,
            samplesCount: metadata.samplesCount,
          },
          computedFields: {
            xMax: (_: unknown, additionalFields: { samplingFrequency: number; samplesCount: number }) =>
              (1000.0 * additionalFields.samplesCount) / additionalFields.samplingFrequency,
          },
        }),
      }
    );
  }
}
