import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  FilesUploadJob,
  FilesUploadRequest,
  UploadStatus,
} from '@features/files-upload/interface/files-upload.interface';
import { Progress } from '@features/files-upload/interface/progress.interface';
import { AStrionApiError } from '@modules/error-handling/app-error/app-error.class';

export const FilesUploadActions = createActionGroup({
  source: 'FilesUpload',
  events: {
    uploadRequested: props<FilesUploadRequest>(),
    uploadCancelRequested: emptyProps(),
    uploadCanceled: emptyProps(),
    uploadFailed: props<{ apiError: AStrionApiError; jobIdx: number }>(),
    noop: emptyProps(),

    jobStartRequired: props<{ jobIdx: number }>(),
    jobsAdded: props<{ jobs: FilesUploadJob[] }>(),
    jobProgressed: props<{ progress: Progress }>(),
    jobEnded: props<{ dtos: UploadStatus[] }>(),

    uploadFinishedWithError: emptyProps(),
    jobsClearRequested: props<{ showMessage: boolean }>(),

    uploadDialogRequested: emptyProps(),
    uploadDialogOpen: emptyProps(),
    uploadDialogClosed: emptyProps(),
  },
});
