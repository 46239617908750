import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, mergeMap, Observable } from 'rxjs';
import Keycloak from 'keycloak-js';

import { authFeature } from './shared/store/auth.feature';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private keycloak: Keycloak
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.keycloak.token;
    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
    }

    return this.store.select(authFeature.selectChosenProject).pipe(
      first(),
      mergeMap(chosenProject => {
        const chosenProjectRequest = chosenProject
          ? request.clone({
              headers: request.headers.set('Chosenproject', chosenProject.fullname),
            })
          : request;
        return next.handle(chosenProjectRequest);
      })
    );
  }
}
