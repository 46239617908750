@let _isOver = isOver();

<h1 mat-dialog-title>Files upload</h1>
<mat-dialog-content class="flex flex-grow flex-col gap-2">
  <mat-checkbox [checked]="showSuccess()" (change)="setShowSuccess($event.checked)" class="primary-checkbox">
    Show successful uploads
  </mat-checkbox>
  <mat-progress-bar mode="determinate" [value]="100 * globalProgressRatio()" />
  <div class="relative flex-grow">
    <mat-nav-list class="absolute inset-0 overflow-y-auto">
      @for (file of shownUploads(); track $index; let index = $index) {
        <mat-list-item>
          <app-file-upload-icon [status]="file" matListItemIcon />
          <span matListItemTitle>{{ file.filename }}</span>
          @if (file.ok !== undefined && !file.ok) {
            <span matListItemLine>{{ file.status }}</span>
          }
        </mat-list-item>
      }
    </mat-nav-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  @if (!_isOver) {
    <button mat-button (click)="stop()"><mat-icon>close</mat-icon>Abort</button>
  }
  <span class="flex-grow"></span>
  <button mat-button (click)="endOrHide()">
    <mat-icon>{{ _isOver ? 'check' : 'hide' }}</mat-icon>
    {{ _isOver ? 'Ok' : 'Hide' }}
  </button>
</mat-dialog-actions>
