import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _observedValues = new Map<string, BehaviorSubject<unknown>>();

  getObject<T, K extends string = string>(key: K): T | null {
    const s = this.getString(key);
    if (!s) {
      return null;
    }
    return JSON.parse(s) as T;
  }

  getString<K extends string = string>(key: K): string | null {
    return localStorage.getItem(key);
  }

  setObject<T, K extends string = string>(key: K, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
    this._observedValues.get(key)?.next(value);
  }

  setString<K extends string = string>(key: K, value: string) {
    localStorage.setItem(key, value);
    this._observedValues.get(key)?.next(value);
  }

  remove<K extends string = string>(key: K) {
    localStorage.removeItem(key);
  }

  getObjectObservable<T, K extends string = string>(name: K): Observable<T | null> {
    let observable = this._observedValues.get(name) as BehaviorSubject<T | null> | undefined;
    if (!observable) {
      const value = this.getObject<T>(name);
      observable = new BehaviorSubject<T | null>(value);
      this._observedValues.set(name, observable as BehaviorSubject<unknown>);
    }
    return observable;
  }

  getObjectSignal<T, K extends string = string>(name: K): Signal<T | null> {
    return toSignal(this.getObjectObservable(name)) as Signal<T | null>;
  }

  getStringObservable<K extends string = string>(name: K): Observable<string | null> {
    let observable = this._observedValues.get(name) as BehaviorSubject<string | null> | undefined;
    if (!observable) {
      const value = this.getString(name);
      observable = new BehaviorSubject<string | null>(value);
    }
    return observable;
  }

  getStringSignal<K extends string = string>(name: K): Signal<string | null> {
    return toSignal(this.getStringObservable(name)) as Signal<string | null>;
  }
}
