import { createReducer, on } from '@ngrx/store';
import { FILES_UPLOAD_INITIAL_STATE, FilesUploadState } from './files-upload.state';
import { FilesUploadActions } from './files-upload.actions';

export const reducer = createReducer(
  FILES_UPLOAD_INITIAL_STATE,
  on(
    FilesUploadActions.jobsAdded,
    (state, { jobs }): FilesUploadState => ({
      ...state,
      cancelUpload: false,
      jobs: [...state.jobs, ...jobs],
    })
  ),
  on(
    FilesUploadActions.jobStartRequired,
    (state, { jobIdx }): FilesUploadState => ({
      ...state,
      currentJobIdx: jobIdx,
    })
  ),
  on(
    FilesUploadActions.uploadFailed,
    (state, { apiError, jobIdx }): FilesUploadState => ({
      ...state,
      jobs: state.jobs.map((job, i) =>
        i === jobIdx
          ? {
              ...job,
              files: job.files.map(file => ({
                ...file,
                file: undefined,
                status: apiError.displayMessage ?? 'Upload failed',
                ok: false,
              })),
              progress: {
                progress: 1,
                total: 1,
              },
              over: true,
            }
          : job
      ),
      currentJobIdx: null,
    })
  ),
  on(FilesUploadActions.jobEnded, (state, { dtos }): FilesUploadState => {
    const nameToDto = new Map(dtos.map(dto => [dto.filename, dto]));
    return {
      ...state,
      jobs: state.jobs.map((job, idx) =>
        idx === state.currentJobIdx
          ? {
              ...job,
              files: job.files.map(file => ({
                ...file,
                ...(nameToDto.get(file.filename) ?? {}),
                file: undefined, // Remove actual file reference
              })),
              progress: {
                ...job.progress,
                progress: job.progress?.total ?? 1,
                total: job.progress?.total ?? 1,
              },
              over: true,
            }
          : job
      ),
      currentJobIdx: null,
    };
  }),
  on(
    FilesUploadActions.jobProgressed,
    (state, { progress }): FilesUploadState => ({
      ...state,
      jobs: state.jobs.map((job, idx) =>
        idx === state.currentJobIdx
          ? {
              ...job,
              progress,
            }
          : job
      ),
    })
  ),
  on(
    FilesUploadActions.jobsClearRequested,
    FilesUploadActions.uploadCancelRequested,
    (state): FilesUploadState => ({
      ...state,
      jobs: [],
      currentJobIdx: null,
    })
  ),
  on(FilesUploadActions.uploadCancelRequested, state => ({
    ...state,
    cancelUpload: true,
  })),
  on(FilesUploadActions.uploadDialogOpen, state => ({
    ...state,
    dialogOpen: true,
  })),
  on(FilesUploadActions.uploadDialogClosed, state => ({
    ...state,
    dialogOpen: false,
  }))
);
