export enum LoadingState {
  Unknown = 0,
  Loading = 1,
  Loaded = 2,
  NotFound = 3,
  Error = 4,
}

export interface LoadableItem {
  loadingState: LoadingState;
}

export function loaded(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.Loaded;
}

export function loading(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.Loading;
}

export function loadingError(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.Error;
}

export function notFound(loadableItem: LoadableItem): boolean {
  return loadableItem.loadingState === LoadingState.NotFound;
}
