import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './sensor-trajectories.reducer';
import { name } from './sensor-trajectories.state';
import { TrajectoriesList } from '../interfaces/trajectory.interface';

export const sensorTrajectoriesFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectPeakTrajectories, selectHarmonicSeriesTrajectories }) => ({
    selectTrajectories: createSelector(
      selectPeakTrajectories,
      selectHarmonicSeriesTrajectories,
      (peakTrajectories, harmonicSeriesTrajectories): TrajectoriesList | null =>
        !!peakTrajectories || !!harmonicSeriesTrajectories
          ? {
              peakTrajectories: peakTrajectories,
              harmonicSeriesTrajectories: harmonicSeriesTrajectories,
            }
          : null
    ),
  }),
});
