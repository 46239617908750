import { AStrionSignal } from './astrion-signal.interface';

export enum AStrionSignalUploadStatus {
  Unknown = 'Unknown',
  Success = 'Success',
  Error = 'Error',
  InvalidDataType = 'InvalidDataType',
  InvalidDataContent = 'InvalidDataContent',
  InvalidFileContent = 'InvalidFileContent',
  InvalidSamplingFrequency = 'InvalidSamplingFrequency',
  NotEnoughSamples = 'NotEnoughSamples',
  TooManySamples = 'TooManySamples',
  DifferentSamplingCondition = 'DifferentSamplingCondition',
}

export const code2status = (code: string) => {
  return Object.values(AStrionSignalUploadStatus as unknown as string[]).includes(code)
    ? (code as unknown as AStrionSignalUploadStatus)
    : AStrionSignalUploadStatus.Unknown;
};

export const statusMessage = (status: AStrionSignalUploadStatus): string => {
  switch (status) {
    case AStrionSignalUploadStatus.Success:
      return 'Upload successful';
    case AStrionSignalUploadStatus.InvalidDataContent:
      return 'Invalid signal data.';
    case AStrionSignalUploadStatus.InvalidDataType:
      return 'Unknown data type in signal.';
    case AStrionSignalUploadStatus.InvalidFileContent:
      return 'Unknown or invalid file format.';
    case AStrionSignalUploadStatus.InvalidSamplingFrequency:
      return 'Invalid sampling frequency provided.';
    case AStrionSignalUploadStatus.TooManySamples:
      return 'Signal has too many samples. The limit is 600 000 for now.';
    case AStrionSignalUploadStatus.DifferentSamplingCondition:
      return 'Signal has not same sampling frequency or sample counts than the others.';
    default:
      return 'Unknown error.';
  }
};

export function isSuccess(status: AStrionSignalUploadStatus): boolean {
  return status === AStrionSignalUploadStatus.Success;
}

export interface AStrionSignalUploadResult {
  name: string;
  status: AStrionSignalUploadStatus;
  signal: AStrionSignal | null;
}
