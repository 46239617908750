import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, model } from '@angular/core';
import { MaterialModule } from '@modules/material.module';
import { FileUploadIconComponent } from './file-upload-icon/file-upload-icon.component';
import { FilesUploadService } from '@features/files-upload/shared/services/files-upload.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-progress-dialog',
  imports: [MaterialModule, CommonModule, FileUploadIconComponent],
  templateUrl: './upload-progress-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadProgressDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<UploadProgressDialogComponent>,
    private service: FilesUploadService
  ) {
    effect(() => {
      if (this.isOver() && !this.hasErrors()) {
        this.end();
      }
    });
  }

  uploads = this.service.allFilesStatuses;
  globalProgressRatio = this.service.globalProgressRatio;
  isOver = this.service.uploadOver;
  hasErrors = this.service.hasErrors;

  showSuccess = model(false);
  setShowSuccess = (value: boolean) => this.showSuccess.set(value);

  shownUploads = computed(() => {
    const showSuccess = this.showSuccess();
    const uploads = this.uploads();
    const filtered = showSuccess ? uploads : uploads.filter(upload => upload.ok !== true);
    return filtered.sort((a, b) => {
      if (a.ok !== false && b.ok === false) {
        return 1;
      } else if (a.ok === false && b.ok !== false) {
        return -1;
      } else {
        return a.filename.localeCompare(b.filename);
      }
    });
  });

  stop() {
    this.service.cancelUpload();
    this.dialogRef.close();
  }

  endOrHide() {
    if (this.isOver()) {
      this.end();
    } else {
      this.hide();
    }
  }

  end() {
    // Don't show snackbar
    this.service.validateUploads(false);
    this.dialogRef.close();
  }

  hide() {
    this.dialogRef.close();
  }
}
