import { Routes } from '@angular/router';

export const SIGNAL_REPORT_ROUTES: Routes = [
  {
    path: ':id',
    loadComponent: () =>
      import('./views/one-page-signal-report/one-page-signal-report.component').then(
        mod => mod.OnePageSignalReportComponent
      ),
    data: {
      signalReport: true,
    },
  },
];
