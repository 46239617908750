import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AUTH_ROUTES } from './auth.routes';
import { ProjectRouter } from './shared/services/projectRouter';
import { AuthEffects } from './shared/store/auth.effects';
import { authFeature } from './shared/store/auth.feature';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(AUTH_ROUTES),
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [ProjectRouter],
})
export class AuthModule {}
