import { LoadableItem, loaded } from '@shared/interfaces/loading-state';

export enum ComputationStepProgress {
  Unknown = 0,
  Completed = 1,
  InProgress = 2,
  NotComputed = 3,
}

export interface ComputationStep extends LoadableItem {
  progress: ComputationStepProgress;
}

export function isComputationStep(loadableItem: LoadableItem): loadableItem is ComputationStep {
  return (loadableItem as ComputationStep).progress !== undefined;
}

export function computationCompleted(computationStep: ComputationStep): boolean {
  return (
    loaded(computationStep) &&
    (computationStep.progress === ComputationStepProgress.Completed ||
      computationStep.progress === ComputationStepProgress.NotComputed)
  );
}

export function computationInProgress(computationStep: ComputationStep): boolean {
  return loaded(computationStep) && computationStep.progress === ComputationStepProgress.InProgress;
}

export function notComputed(computationStep: ComputationStep): boolean {
  return loaded(computationStep) && computationStep.progress === ComputationStepProgress.NotComputed;
}

export const stringToComputationStepProgress = (progress: string): ComputationStepProgress => {
  switch (progress) {
    case 'Completed':
      return ComputationStepProgress.Completed;
    case 'InProgress':
      return ComputationStepProgress.InProgress;
    case 'NotComputed':
      return ComputationStepProgress.NotComputed;
    default:
      return ComputationStepProgress.Unknown;
  }
};
