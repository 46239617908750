import { Component, input } from '@angular/core';
import { UploadStatusWithProgress } from '@features/files-upload/interface/files-upload.interface';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-file-upload-icon',
  imports: [MaterialModule],
  templateUrl: './file-upload-icon.component.html',
})
export class FileUploadIconComponent {
  status = input.required<UploadStatusWithProgress>();
}
