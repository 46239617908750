import { Injectable } from '@angular/core';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, tap } from 'rxjs';

import { ProjectRouter } from '@features/auth/shared/services/projectRouter';
import { concatLatestFrom } from '@ngrx/operators';
import { mapSensorFromDto } from '../interfaces/sensor.interface';
import { SensorsApiService } from '../services/sensors-api.service';
import { SensorsActions } from './sensors.actions';
import { sensorsFeature } from './sensors.feature';
@Injectable()
export class SensorsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: ProjectRouter,
    private sensorsApiService: SensorsApiService
  ) {}

  createSensorEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorsActions.sensorCreateRequested),
      concatMap(({ sensor }) =>
        this.sensorsApiService.createSensor(sensor).pipe(
          map(created => SensorsActions.sensorCreated({ sensor: mapSensorFromDto(created) })),
          catchApiError(false, () => SensorsActions.sensorCreateFailed())
        )
      )
    );
  });

  editSensorEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorsActions.sensorUpdateRequested),
      concatMap(({ sensor }) =>
        this.sensorsApiService.updateSensor(sensor).pipe(
          map(updated => SensorsActions.sensorUpdated({ sensor: mapSensorFromDto(updated) })),
          catchApiError(false, () => SensorsActions.sensorUpdateFailed())
        )
      )
    );
  });

  deleteSensorEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorsActions.sensorDeleteRequested),
      concatMap(({ id, equipmentId }) =>
        this.sensorsApiService.deleteSensor(id).pipe(
          map(() => SensorsActions.sensorDeleted({ id, equipmentId })),
          catchApiError(true, () => SensorsActions.sensorDeleteFailed())
        )
      )
    );
  });

  navigateOnSensorDeletionEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SensorsActions.sensorDeleted),
        concatLatestFrom(() => [this.store.select(sensorsFeature.selectCurrentSensorId)]),
        tap(([{ id, equipmentId }, currentSensorId]) => {
          if (id === currentSensorId) {
            this.router.navigateByUrl(`/equipment/${equipmentId}`);
          }
        })
      );
    },
    { dispatch: false }
  );
}
