import { Component, computed, effect, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UploadBackgroundButtonComponent } from '@features/files-upload/components/upload-background-button/upload-background-button.component';
import { FilesUploadService } from '@features/files-upload/shared/services/files-upload.service';

import { MaterialModule } from '@modules/material.module';
import { SidenavComponent } from '@navigation/sidenav/sidenav.component';
import { LocalStorageService } from '@services/local-storage.service';

const EXPANDED_STORED_NAME = 'astrion-app-root-sidenavExpanded';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet, MaterialModule, SidenavComponent, UploadBackgroundButtonComponent],
})
export class AppComponent {
  sidenavExpanded = signal<boolean>(this.storedSidenavExpansion);
  uploadInProgress = this.uploadService.uploadInProgress;
  uploadDialogOpen = this.uploadService.isDialogOpen;
  showUploadButton = computed(() => this.uploadInProgress() && !this.uploadDialogOpen());

  toggleSidenavExpansion = () => {
    this.sidenavExpanded.update(value => !value);
  };

  constructor(
    private storage: LocalStorageService,
    private uploadService: FilesUploadService
  ) {
    effect(() => {
      this.storedSidenavExpansion = this.sidenavExpanded();
    });
  }

  private get storedSidenavExpansion() {
    return (this.storage.getString(EXPANDED_STORED_NAME) ?? 'true') === 'true';
  }

  private set storedSidenavExpansion(expanded: boolean) {
    this.storage.setString(EXPANDED_STORED_NAME, expanded ? 'true' : 'false');
  }
}
