import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './sensor-graphs.reducer';
import { name } from './sensor-graphs.state';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { recordMap } from '@tools/utilities/record-utilities';

export const sensorGraphsFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectSensorsCurves }) => {
    const selectCurves = (sensorId: SensorId) =>
      createSelector(selectSensorsCurves, sensorsCurves => (sensorId ? (sensorsCurves[sensorId] ?? {}) : {}));
    return {
      selectCurves,
      selectGraphInput: (sensorId: SensorId) =>
        createSelector(selectCurves(sensorId), curves => ({
          dbName: ASTRION_INDEXEDDB_NAME,
          storeName: ASTRION_INDEXEDDB_TABLES.trajectoryTrend,
          curves: Object.values(curves).sort((a, b) => a.id.localeCompare(b.id)),
        })),
      selectCurveNameToId: (sensorId: SensorId) =>
        createSelector(selectCurves(sensorId), curves => recordMap(curves, (key, curve) => [curve.id, key])),
      selectCurveIds: (sensorId: SensorId) => createSelector(selectCurves(sensorId), curves => Object.keys(curves)),
      selectCurveIdsSet: createSelector(
        selectSensorsCurves,
        sensorCurves => new Set(Object.values(sensorCurves).flatMap(curves => Object.keys(curves)))
      ),
    };
  },
});
