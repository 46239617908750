import {
  ComputationStepProgress,
  stringToComputationStepProgress,
} from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';

import { Fusion, FusionDto } from '../interface/fusion';
import { pfaToPmc } from './pfa-to-pmc-mapping';

export function mapFusionFromDto(dto: FusionDto): Fusion {
  const fusion: Fusion = {
    progress: stringToComputationStepProgress(dto.progress),
    loadingState: LoadingState.Loaded,
    data: undefined,
  };

  if (fusion.progress === ComputationStepProgress.Completed && dto.data) {
    fusion.data = {
      ...dto.data,
      noiseArea: {
        ...dto.data.noiseArea,
        minAmplitudes: {
          contentPath: dto.data.noiseArea.minAmplitudesContentPath,
          loadingState: LoadingState.Unknown,
        },
        maxAmplitudes: {
          contentPath: dto.data.noiseArea.maxAmplitudesContentPath,
          loadingState: LoadingState.Unknown,
        },
      },
      peaks: dto.data.fusionPeaks.map(peak => ({
        ...peak,
        pmc: pfaToPmc(peak.pfa),
      })),
    };
  }

  return fusion;
}
