import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FilesUploadActions } from './files-upload.actions';
import { concat, concatMap, map, of, takeWhile, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { filesUploadFeature } from './files-upload.feature';
import { uploadProgress } from '@features/files-upload/operators/upload-progress.operator';
import { isProgress, Progress } from '@features/files-upload/interface/progress.interface';
import { filesBatch } from '@features/files-upload/operators/files-batch.operator';
import { MatDialog } from '@angular/material/dialog';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { UploadProgressDialogComponent } from '@features/files-upload/components/upload-progress-dialog/upload-progress-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class FilesUploadEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  uploadRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesUploadActions.uploadRequested),
      filesBatch(10),
      map(jobs => FilesUploadActions.jobsAdded({ jobs }))
    );
  });

  openUploadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesUploadActions.uploadRequested, FilesUploadActions.uploadDialogRequested),
      concatMap(() =>
        concat(
          of(FilesUploadActions.uploadDialogOpen()),
          this.dialog
            .open(UploadProgressDialogComponent, {
              minWidth: '30vw',
              minHeight: '70vh',
              disableClose: true,
            })
            .afterClosed()
            .pipe(map(() => FilesUploadActions.uploadDialogClosed()))
        )
      )
    );
  });

  uploadFinished$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesUploadActions.jobEnded),
      withLatestFrom(
        this.store.select(filesUploadFeature.selectUploadOver),
        this.store.select(filesUploadFeature.selectHasErrors)
      ),
      map(([, over, error]) =>
        over
          ? error
            ? FilesUploadActions.uploadFinishedWithError()
            : FilesUploadActions.jobsClearRequested({ showMessage: true })
          : FilesUploadActions.noop()
      )
    );
  });

  jobsValidated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilesUploadActions.jobsClearRequested),
        tap(({ showMessage }) => {
          if (showMessage) {
            this.snackbar.open('Upload successful.', 'Dismiss', {
              duration: 5000,
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  jobsFinishedWithErrors$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilesUploadActions.uploadFinishedWithError),
        tap(() => {
          this.snackbar.open('Errors in upload.', 'Dismiss', {
            duration: 5000,
          });
        })
      );
    },
    { dispatch: false }
  );

  checkForJobStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesUploadActions.jobsAdded, FilesUploadActions.jobEnded, FilesUploadActions.uploadFailed),
      withLatestFrom(
        this.store.select(filesUploadFeature.selectCurrentJobIdx),
        this.store.select(filesUploadFeature.selectFirstPendingJobIdx)
      ),
      map(([, currentJob, jobIdx]) =>
        currentJob !== null || jobIdx < 0 ? FilesUploadActions.noop() : FilesUploadActions.jobStartRequired({ jobIdx })
      )
    );
  });

  startNewJob$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesUploadActions.jobStartRequired),
      withLatestFrom(this.store.select(filesUploadFeature.selectJobs)),
      concatMap(([{ jobIdx }, jobs]) => {
        const { upload, dtoCallback, files } = jobs[jobIdx];
        return upload(files.map(file => file.file!)).pipe(
          uploadProgress<object>(),
          withLatestFrom(this.store.select(filesUploadFeature.selectCancelUpload)),
          map(([result, cancel]: [Progress | object, boolean]) =>
            cancel
              ? FilesUploadActions.uploadCanceled()
              : isProgress(result)
                ? FilesUploadActions.jobProgressed({ progress: result })
                : FilesUploadActions.jobEnded({ dtos: dtoCallback(result) })
          ),
          takeWhile(action => action.type != FilesUploadActions.uploadCanceled.type, true),
          catchApiError(false, apiError => FilesUploadActions.uploadFailed({ apiError, jobIdx }))
        );
      })
    );
  });
}
