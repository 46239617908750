import { Injectable } from '@angular/core';
import { HttpWrapperService } from '@astrion-webtools/graph';
import environment from '@environments/environment.json';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { Observable } from 'rxjs';

import { CyclesDto } from '../interface/cycles';
import { FusionDto } from '../interface/fusion';
import { HarmonicSeriesDto } from '../interface/harmonic-series';
import { SpectralAnalysisDto } from '../interface/spectral-analysis';

@Injectable({
  providedIn: 'root',
})
export class SpectralAnalysisApiService {
  constructor(private api: HttpWrapperService) {}

  getSpectralAnalysis(signalId: AStrionSignalId): Observable<SpectralAnalysisDto> {
    return this.api.get<SpectralAnalysisDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/spectral-analysis/${signalId}`
    );
  }

  getCyclesData(signalId: AStrionSignalId): Observable<CyclesDto> {
    return this.api.get<CyclesDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/spectral-analysis/cycles/${signalId}`
    );
  }

  getFusionData(signalId: AStrionSignalId): Observable<FusionDto> {
    return this.api.get<FusionDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/spectral-analysis/fusion/${signalId}`
    );
  }

  getHarmonicSeriesData(signalId: AStrionSignalId): Observable<HarmonicSeriesDto> {
    return this.api.get<HarmonicSeriesDto>(
      `${environment.BASE_API_URL}/reports/signal-reports/spectral-analysis/harmonics/${signalId}`
    );
  }
}
