import { Injectable } from '@angular/core';
import { DataValidationActions } from '@features/data-validation/shared/store/data-validation.actions';
import { SpectralAnalysisActions } from '@features/spectral-analysis/shared/store/spectral-analysis.actions';
import { SignalOverviewActions } from '@features/signal-overview/shared/store/signal-overview.actions';
import { dto2signal } from '@features/signals/shared/interface/astrion-signal.mappers';
import { SignalsApiService } from '@features/signals/shared/services/signals-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs';

import { ProjectRouter } from '@features/auth/shared/services/projectRouter';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { AStrionWebError } from '@modules/error-handling/app-error/app-error.class';
import { concatLatestFrom } from '@ngrx/operators';
import { SignalReportActions } from './signal-report.actions';
import { signalReportFeature } from './signal-report.feature';

@Injectable()
export class SignalReportEffects {
  constructor(
    private actions$: Actions,
    private signalsApi: SignalsApiService,
    private store: Store,
    private router: ProjectRouter
  ) {}

  tryFetchSignalBaseDataEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      switchMap(({ signalId }) =>
        this.signalsApi.getSignal(signalId).pipe(
          map(dto => SignalReportActions.signalBaseDataFetched({ signalId, signal: dto2signal(dto) })),
          catchApiError(false, apiError =>
            apiError.httpError.status == 404
              ? SignalReportActions.signalBaseDataNotFound({ signalId })
              : SignalReportActions.signalBaseDataFetchFailed({ signalId })
          )
        )
      )
    );
  });

  requestSignalContentResetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      map(({ signalId }) => SignalOverviewActions.signalContentReset({ signalId }))
    );
  });

  requestDataValidationResetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      map(({ signalId }) => DataValidationActions.dataValidationReset({ signalId }))
    );
  });

  requestSpectralAnalysisResetEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetchRequested),
      map(({ signalId }) => SpectralAnalysisActions.spectralAnalysisReset({ signalId }))
    );
  });

  requestSignalContentFetchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetched),
      map(({ signalId }) => SignalOverviewActions.signalContentFetchRequested({ signalId }))
    );
  });

  requestDataValidationFetchOnOverviewFetchedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetched),
      map(({ signalId }) =>
        DataValidationActions.dataValidationFetchRequested({
          signalId,
        })
      )
    );
  });

  requestSpectralAnalysisFetchEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalReportActions.signalBaseDataFetched),
      map(({ signalId }) => SpectralAnalysisActions.spectralAnalysisFetchRequested({ signalId }))
    );
  });

  navigateOnSignalDeletionEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SignalsActions.signalDeleted),
        concatLatestFrom(() => [this.store.select(signalReportFeature.selectSignalId)]),
        tap(([{ signalId, sensorId }, reportSignalId]) => {
          if (signalId === reportSignalId) {
            this.router.navigateByUrl(`/sensor/${sensorId}`);
            throw new AStrionWebError(
              'Current report signal deleted',
              'The signal of the report you were in just got deleted.'
            );
          }
        })
      );
    },
    { dispatch: false }
  );
}
