import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { filesUploadFeature } from './shared/store/files-upload.feature';
import { EffectsModule } from '@ngrx/effects';
import { FilesUploadEffects } from './shared/store/files-upload.effects';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(filesUploadFeature), EffectsModule.forFeature([FilesUploadEffects])],
})
export class FilesUploadModule {}
