import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './data-validation.reducer';
import { featureName } from './data-validation.state';
import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';

export const dataValidationFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors: ({ selectProgress }) => ({
    selectDataValidationComputed: createSelector(
      selectProgress,
      (progress: ComputationStepProgress): boolean => progress === ComputationStepProgress.Completed
    ),
  }),
});
