import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import environment from '@environments/environment.json';
import { Observable } from 'rxjs';

import { SensorId } from '../interfaces/sensor.interface';
import { SensorDto, SensorPayload } from '../interfaces/sensor.interface';

@Injectable({
  providedIn: 'root',
})
export class SensorsApiService {
  constructor(private httpClient: HttpClient) {}

  getAllSensors(): Observable<SensorDto[]> {
    return this.httpClient.get<SensorDto[]>(`${environment.BASE_API_URL}/operation-management/sensors`);
  }

  deleteSensor(sensorId: SensorId): Observable<void> {
    return this.httpClient.delete<void>(`${environment.BASE_API_URL}/operation-management/sensors/${sensorId}`);
  }

  createSensor(sensorPayload: SensorPayload): Observable<SensorDto> {
    return this.httpClient.post<SensorDto>(`${environment.BASE_API_URL}/operation-management/sensors`, sensorPayload);
  }

  updateSensor(sensorPayload: SensorPayload): Observable<SensorDto> {
    return this.httpClient.put<SensorDto>(`${environment.BASE_API_URL}/operation-management/sensors`, sensorPayload);
  }
}
