import { IndexedDbRowId } from '@astrion-webtools/graph';
import { HarmonicTrajectory, Trajectory } from './trajectory.interface';

export interface TrajectoryTrendDto {
  id: string;
  name: string;
  interlacedValues: IndexedDbRowId;
}

export interface TrajectoryTrend {
  name: string;
  interlacedValues: IndexedDbRowId;
}

export enum TrendType {
  Frequency = 0,
  Energy,
  HarmonicFrequencies,
  HarmonicEnergies,
  HarmonicsCount,
  AverageHarmonicEnergy,
  Regularity,
  THD,
  HarmonicSidebandsSeriesCount,
  HarmonicSidebandsSeriesPercentage,
}

export const trendCompositeKey = (trajectory: Trajectory, trendType: TrendType) =>
  `${trajectory.id}-${trajectory.type}-${trendType}`;

export const harmonicTrendCompositeKey = (trajectory: HarmonicTrajectory, trendType: TrendType) =>
  `${trajectory.id}-${trajectory.type}-${trajectory.rank}-${trendType}`;
