import { map, Observable, OperatorFunction } from 'rxjs';
import { FilesUploadJob, FilesUploadRequest } from '../interface/files-upload.interface';

export function filesBatch(batchSize: number): OperatorFunction<FilesUploadRequest, FilesUploadJob[]> {
  return (source: Observable<FilesUploadRequest>): Observable<FilesUploadJob[]> =>
    source.pipe(
      map(({ files, upload, dtoCallback }) => {
        const nFiles = files.length;
        const jobs: FilesUploadJob[] = [];
        for (let i = 0; i < nFiles; i += batchSize) {
          jobs.push({
            files: files.slice(i, i + batchSize).map(file => ({
              filename: file.name,
              status: '',
              file: file,
            })),
            upload,
            dtoCallback,
            progress: undefined,
            over: false,
          });
        }
        return jobs;
      })
    );
}
