import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilesUploadActions } from '../store/files-upload.actions';
import { filesUploadFeature } from '../store/files-upload.feature';
import { firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UploadFilesComponent } from '@features/files-upload/components/upload-files/upload-files.component';

@Injectable({
  providedIn: 'root',
})
export class FilesUploadService {
  constructor(
    private store: Store,
    private dialog: MatDialog
  ) {}

  get allFilesStatuses() {
    return this.store.selectSignal(filesUploadFeature.selectAllFilesStatuses);
  }

  get globalProgressRatio() {
    return this.store.selectSignal(filesUploadFeature.selectGlobalProgressRatio);
  }

  get uploadOver() {
    return this.store.selectSignal(filesUploadFeature.selectUploadOver);
  }

  get hasErrors() {
    return this.store.selectSignal(filesUploadFeature.selectHasErrors);
  }

  get isDialogOpen() {
    return this.store.selectSignal(filesUploadFeature.selectDialogOpen);
  }

  get uploadInProgress() {
    return this.store.selectSignal(filesUploadFeature.selectUploadInProgress);
  }

  selectUploadFiles(): Promise<File[]> {
    return firstValueFrom(
      this.dialog.open(UploadFilesComponent, { restoreFocus: false, minWidth: '30vw', minHeight: '70vh' }).afterClosed()
    );
  }

  openUploadDialog() {
    this.store.dispatch(FilesUploadActions.uploadDialogRequested());
  }

  validateUploads(showMessage: boolean) {
    this.store.dispatch(FilesUploadActions.jobsClearRequested({ showMessage }));
  }

  cancelUpload() {
    this.store.dispatch(FilesUploadActions.uploadCancelRequested());
  }
}
