import { createFeature, createSelector } from '@ngrx/store';
import { name } from './files-upload.state';
import { reducer } from './files-upload.reducer';
import { UploadStatusWithProgress } from '@features/files-upload/interface/files-upload.interface';

export const filesUploadFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectJobs }) => {
    const selectAllFilesStatuses = createSelector(selectJobs, jobs =>
      jobs
        .map<UploadStatusWithProgress[]>(job =>
          job.files.map<UploadStatusWithProgress>(file => ({
            filename: file.filename,
            status: file.status,
            ok: file.ok,
            progressRatio: job.progress !== undefined ? Math.max(job.progress.progress / job.progress.total, 0.0) : -1,
          }))
        )
        .flat()
    );
    const selectUploadOver = createSelector(selectJobs, jobs => jobs.every(job => job.over));
    const selectHasErrors = createSelector(selectJobs, jobs =>
      jobs.some(job => job.files.some(file => file.ok === false))
    );
    return {
      selectAllFilesStatuses,
      selectFirstPendingJobIdx: createSelector(selectJobs, jobs => jobs.findIndex(job => job.progress === undefined)),
      selectTotalFilesNumber: createSelector(selectJobs, jobs =>
        jobs.reduce((count, job) => count + job.files.length, 0)
      ),
      selectUploadOver,
      selectHasErrors,
      selectGlobalProgressRatio: createSelector(
        selectAllFilesStatuses,
        statuses => statuses.reduce((sum, status) => sum + Math.max(status.progressRatio, 0.0), 0.0) / statuses.length
      ),
      selectUploadInProgress: createSelector(
        selectUploadOver,
        selectHasErrors,
        (isOver, hasErrors) => !isOver || hasErrors
      ),
    };
  },
});
