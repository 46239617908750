import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './auth.reducer';
import { featureName } from './auth.state';
import { Project } from '../interfaces/project';

export const authFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors: ({ selectChosenProject }) => {
    const selectIsAstriisUser = createSelector(
      selectChosenProject,
      (chosenProject: Project | null): boolean =>
        !!chosenProject && !!chosenProject.roles?.find(r => r == 'astriis-user')
    );

    const selectIsEditor = createSelector(
      selectChosenProject,
      (chosenProject: Project | null): boolean => !!chosenProject && !!chosenProject.roles?.find(r => r == 'editor')
    );

    const selectCanRecompute = createSelector(selectIsAstriisUser, (isAstriisUser: boolean): boolean => isAstriisUser);
    const selectCanEdit = createSelector(
      selectIsAstriisUser,
      selectIsEditor,
      (isAstriisUser: boolean, isEditor: boolean): boolean => {
        return isAstriisUser || isEditor;
      }
    );

    return { selectIsAstriisUser, selectIsEditor, selectCanRecompute, selectCanEdit };
  },
});
