import { Injectable } from '@angular/core';
import { dto2Map } from '@features/signals-status/shared/interface/astrion-signals-status.mapper';
import { SignalsStatusApiService } from '@features/signals-status/shared/services/signals-status-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, forkJoin, map } from 'rxjs';

import { ValidationFlagsApiService } from '@features/data-validation/shared/services/validation-flags-api.service';
import { signalsValidationFlagsFromDtos } from '@features/data-validation/shared/utils/validation-flags-mapping';
import { astrionSignalPageSize } from '../interface/astrion-signal-page-size';
import {
  AStrionSignalUploadResult,
  code2status,
  isSuccess,
  statusMessage,
} from '../interface/astrion-signal-upload-result.interface';
import { AStrionSignalDto, UploadResponseAStrionSignalDto } from '../interface/astrion-signal.interface';
import { dto2signal, dto2signals, signal2payload } from '../interface/astrion-signal.mappers';
import { SignalsApiService } from '../services/signals-api.service';
import { SignalsActions } from './signals.actions';
import { FilesUploadActions } from '@features/files-upload/shared/store/files-upload.actions';

@Injectable()
export class SignalsEffects {
  constructor(
    private actions$: Actions,
    private signalsApi: SignalsApiService,
    private signalsStatusApi: SignalsStatusApiService,
    private validationFlagsApi: ValidationFlagsApiService,
    private store: Store
  ) {}

  fetchSensorSignalsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.sensorSignalsFetchRequested),
      concatMap(({ sensorId, isComputableSensor }) => {
        return this.signalsApi.getSensorSignals(sensorId).pipe(
          map((signalsDto: AStrionSignalDto[]) =>
            SignalsActions.sensorSignalsFetched({
              sensorId,
              isComputableSensor,
              signals: dto2signals(signalsDto),
            })
          ),
          catchApiError(false, () => SignalsActions.sensorSignalsFetchFailed())
        );
      })
    );
  });

  fetchSensorFirstVisibleSignalStatusEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.sensorSignalsFetched),
      map(({ isComputableSensor, signals }) => {
        if (isComputableSensor && signals.length > astrionSignalPageSize) {
          const signalIds = signals.slice(0, astrionSignalPageSize).map(s => s.id);
          return SignalsActions.signalsStatusFetchRequested({ signalIds });
        } else {
          return SignalsActions.noops();
        }
      })
    );
  });

  fetchSensorStatusEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.sensorSignalsFetched),
      map(({ isComputableSensor, sensorId }) => {
        if (isComputableSensor) {
          return SignalsActions.sensorStatusFetchRequested({ sensorId });
        } else {
          return SignalsActions.noops();
        }
      })
    );
  });

  fetchSignalsStatusEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalsStatusFetchRequested),
      concatMap(({ signalIds }) => {
        return forkJoin([
          this.signalsStatusApi.getDataValidationSignalsStatus(signalIds),
          this.signalsStatusApi.getSpectralAnalysisSignalsStatus(signalIds),
          this.validationFlagsApi.getValidationFlagsList(signalIds),
        ]).pipe(
          map(results =>
            dto2Map({
              dataValidationStatus: results[0],
              spectralAnalysisStatus: results[1],
              validationFlags: signalsValidationFlagsFromDtos(results[2]),
            })
          ),
          map(statuses => SignalsActions.signalsStatusFetched({ statuses })),
          catchApiError(false, () => SignalsActions.signalsStatusFetchFailed())
        );
      })
    );
  });

  fetchSensorStatusEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.sensorStatusFetchRequested),
      concatMap(({ sensorId }) => {
        return this.signalsStatusApi.getSensorStatuses(sensorId).pipe(
          map(dto2Map),
          map(statuses => SignalsActions.signalsStatusFetched({ statuses, sensorFullyLoaded: true })),
          catchApiError(false, () => SignalsActions.signalsStatusFetchFailed())
        );
      })
    );
  });

  tryDeleteSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalDeletionRequested),
      concatMap(({ signal }) =>
        this.signalsApi.deleteSignal(signal.id).pipe(
          map(signalId => SignalsActions.signalDeleted({ signalId, sensorId: signal.sensorId })),
          catchApiError(false, () => SignalsActions.signalDeletionFailed())
        )
      )
    );
  });

  tryUpdateSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalUpdateRequested),
      concatMap(({ signal }) => {
        return this.signalsApi.updateSignal(signal.id, signal2payload(signal)).pipe(
          map(() => SignalsActions.signalUpdated({ signal })),
          catchApiError(false, () => SignalsActions.signalUpdateFailed({ signal }))
        );
      })
    );
  });

  tryUploadSignalsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalsUploadRequested),
      map(({ sensorId, files }) =>
        FilesUploadActions.uploadRequested({
          files,
          upload: f => this.signalsApi.uploadSignals(sensorId, f),
          dtoCallback: (dto: unknown) => {
            const dtos = dto as UploadResponseAStrionSignalDto[];
            const uploads = dtos.map<AStrionSignalUploadResult>(signal => ({
              name: signal.name,
              status: code2status(signal.status),
              signal: signal.data ? dto2signal(signal.data) : null,
            }));
            this.store.dispatch(
              SignalsActions.signalsUploaded({
                uploads,
                sensorId,
              })
            );
            return uploads.map(signal => ({
              filename: signal.name,
              status: statusMessage(signal.status),
              ok: isSuccess(signal.status),
            }));
          },
        })
      )
    );
  });

  tryFetchSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalFetchRequested, SignalsActions.signalDataFetchRequested),
      concatMap(({ signalId }) =>
        this.signalsApi.getSignal(signalId).pipe(
          map((signalDto: AStrionSignalDto) => SignalsActions.signalFetched({ signal: dto2signal(signalDto) })),
          catchApiError(false, () => SignalsActions.signalFetchFailed())
        )
      )
    );
  });

  tryDownloadSignalEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.signalDownloadRequested),
      concatMap(({ signalId }) =>
        this.signalsApi.downloadSignal(signalId).pipe(
          map(([name, content]: [name: string, content: Blob]) => {
            const contentUrl = URL.createObjectURL(content);

            const virtualLink = document.createElement('a');

            virtualLink.href = contentUrl;
            virtualLink.download = name;

            virtualLink.click();

            URL.revokeObjectURL(contentUrl);

            return SignalsActions.signalDownloaded();
          }),
          catchApiError(false, () => SignalsActions.signalDownloadFailed())
        )
      )
    );
  });
}
