import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './realtime.reducer';
import { name } from './realtime.state';
import { ConnectionStatus } from '../interfaces/connection-status';

export const realtimeFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectConnectionStatus }) => ({
    selectIsConnected: createSelector(
      selectConnectionStatus,
      (status: ConnectionStatus | undefined): boolean => status === ConnectionStatus.Connected
    ),
  }),
});
