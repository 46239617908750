import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Project } from '../interfaces/project';
import { User } from '../interfaces/user';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    parseTokenRequested: emptyProps(),
    loginRequested: props<{ redirectUri?: string }>(),
    loggedIn: props<{ user: User; projects: Project[] }>(),
    loginFailed: emptyProps(),
    logoutRequested: props<{ redirectUri: string }>(),
    loggedOut: emptyProps(),

    projectChangeRequested: props<{ project: Project | null; redirectTo?: string | null }>(),
    projectChanged: props<{ project: Project | null }>(),

    skipped: emptyProps(),
  },
});
