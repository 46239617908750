import { effect, inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular';
import { map, switchMap } from 'rxjs/operators';

import { buildProjectsFromClaims, Project } from '../interfaces/project';
import { User } from '../interfaces/user';
import { JwtService } from '../services/jwt.service';
import { ProjectRouter } from '../services/projectRouter';
import { AuthActions } from './auth.actions';
import { LocalStorageService } from '@services/local-storage.service';
import { KEYCLOAK_EVENT_SIGNAL, KeycloakEventType, ReadyArgs, typeEventArgs } from 'keycloak-angular';
import { Store } from '@ngrx/store';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';

import Keycloak from 'keycloak-js';
import { from } from 'rxjs';

@Injectable()
export class AuthEffects {
  tryLoginEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loginRequested),
      switchMap(({ redirectUri }: { redirectUri?: string }) =>
        from(this.keycloak.login({ redirectUri })).pipe(
          map(() => {
            return AuthActions.parseTokenRequested();
          })
        )
      )
    );
  });

  tryParseTokenEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.parseTokenRequested),
      map(() => {
        const token = this.keycloak.token;
        if (token !== undefined) {
          const id = this.jwtService.getStringClaim(token, 'sub');
          const username = this.jwtService.getStringClaim(token, 'name');
          const given_name = this.jwtService.getStringClaim(token, 'given_name');
          const family_name = this.jwtService.getStringClaim(token, 'family_name');
          const email = this.jwtService.getStringClaim(token, 'email');
          const tenantId = this.jwtService.getStringClaim(token, 'tenant-id');
          const projectClaims = this.jwtService.getArrayClaim(token, 'projects');
          const projects = buildProjectsFromClaims(projectClaims);
          const user: User = { id, username, given_name, family_name, email, tenantId };
          return AuthActions.loggedIn({ user: user, projects });
        } else {
          return AuthActions.loginFailed();
        }
      })
    );
  });

  loginSuccessEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loggedIn),
      map(({ projects }: { projects: Project[] }) => {
        const localProject = this.storage.getObject<Project>('chosenProject');
        if (projects.find(p => p.id == localProject?.id)) {
          return AuthActions.projectChangeRequested({ project: localProject });
        } else if (projects.length == 1) {
          return AuthActions.projectChangeRequested({ project: projects[0] });
        } else {
          // let the guard redirect to choose project if necessary
          return AuthActions.skipped();
        }
      })
    );
  });

  chooseProjectEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.projectChangeRequested),
      map(({ project, redirectTo }: { project: Project | null; redirectTo?: string | null }) => {
        const prevProject = this.storage.getObject<Project>('chosenProject');
        const changed = prevProject?.id !== project?.id;
        if (changed) {
          if (project !== null) {
            this.storage.setObject('chosenProject', project);
          } else {
            this.storage.remove('chosenProject');
          }
        }
        if (redirectTo) {
          this.router.navigateByUrl(redirectTo);
        }
        return changed ? AuthActions.projectChanged({ project }) : AuthActions.skipped();
      })
    );
  });

  tryLogoutEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.logoutRequested),
      switchMap(({ redirectUri }: { redirectUri: string }) =>
        from(this.keycloak.logout({ redirectUri })).pipe(
          map(() => {
            localStorage.removeItem('chosenProject');
            return AuthActions.loggedOut();
          })
        )
      )
    );
  });

  sentryOnLoginEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loggedIn),
      map(({ user }) => {
        // ugly but they use "name" instead of the correct "username" in the showReportDialog
        Sentry.setUser({ ...user, name: user.username });
        Sentry.setTag('tenant_id', user.tenantId);
        return AuthActions.skipped();
      })
    );
  });

  sentryOnLogoutEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.loggedOut),
      map(() => {
        Sentry.setUser(null);
        Sentry.setTag('tenant_id', undefined);
        return AuthActions.skipped();
      })
    );
  });

  sentryOnProjectChangeRequestedEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AuthActions.projectChangeRequested),
      map(({ project }) => {
        Sentry.setTag('project_id', project?.id);
        Sentry.setTag('project_name', project?.name);

        if (!project || !project.roles) {
          Sentry.setTag('project_role', undefined);
        } else {
          if (project.roles.length > 0) {
            // TODO support multiple roles.
            // Sentry does not support multiple tag values
            Sentry.setTag('project_role', project.roles[0]);
          }
        }
        return AuthActions.skipped();
      })
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly jwtService: JwtService,
    private readonly router: ProjectRouter,
    private readonly store: Store,
    private readonly realtimeClient: RealtimeClientService,
    private readonly keycloak: Keycloak,
    private storage: LocalStorageService
  ) {
    const keycloakSignal = inject(KEYCLOAK_EVENT_SIGNAL);

    effect(() => {
      const keycloakEvent = keycloakSignal();
      if (keycloakEvent.type === KeycloakEventType.Ready) {
        const authenticated = typeEventArgs<ReadyArgs>(keycloakEvent.args);
        if (authenticated) {
          this.store.dispatch(AuthActions.parseTokenRequested());
        }
      } else if (keycloakEvent.type === KeycloakEventType.AuthRefreshSuccess) {
        this.realtimeClient.reconnect();
      } else if (keycloakEvent.type === KeycloakEventType.TokenExpired) {
        this.realtimeClient.disconnect();
      }
    });
  }
}
