import { ComputationStepProgress } from '@features/signal-report/shared/interface/computation-step-progress';
import { LoadingState } from '@shared/interfaces/loading-state';
import { HarmonicBase, HarmonicBaseDto, PeakSerie } from './harmonic-base';
import { HarmonicSidebands, HarmonicSidebandsDto } from './harmonic-sidebands';

export interface HarmonicDto extends HarmonicBaseDto {
  sidebands: HarmonicSidebandsDto[];
}

export interface HarmonicSerieDto {
  fundamentalFrequency: number;
  density: number;
  regularity: number;
  richness: number;
  energy: number;
  thd: number;
  harmonics: HarmonicDto[];
}

export interface HarmonicSeriesDto {
  progress: string;
  data?: HarmonicSerieDto[];
}

export interface Harmonic extends HarmonicBase {
  sidebands: HarmonicSidebands[];
}

export interface HarmonicSerie extends PeakSerie {
  fundamentalFrequency: number;
  harmonics: Harmonic[];
}

export interface HarmonicSeries {
  progress: ComputationStepProgress;
  loadingState: LoadingState;
  data?: HarmonicSerie[];
}

export const defaultHarmonicSeriesWithLoadingState = (
  loadingState: LoadingState = LoadingState.Unknown
): HarmonicSeries => ({
  progress: ComputationStepProgress.Unknown,
  loadingState,
});
