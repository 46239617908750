import { Injectable } from '@angular/core';
import { SensorGraphsActions } from '@features/sensor-graphs/shared/store/sensor-graphs.actions';
import { TrajectoriesApiService } from '@features/sensor-trajectories/services/trajectories-api/trajectories-api.service';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { isNotFoundStatus } from '@modules/error-handling/app-error/app-error.class';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, tap } from 'rxjs';

import { harmonicTrendCompositeKey, trendCompositeKey } from '../interfaces/trajectory-trend.interface';
import { mapTrajectoriesFromDto } from '../utils/trajectories-dto';
import { getHarmonicTrajectoryTrendCurve, getTrajectoryTrendCurve } from '../utils/trajectory-trend';
import { SensorTrajectoriesActions } from './sensor-trajectories.actions';
import { download } from '@tools/utilities/download';

@Injectable()
export class SensorTrajectoriesEffect {
  constructor(
    private actions$: Actions,
    private trajectoriesApiService: TrajectoriesApiService
  ) {}

  fetchSensorTrajectoriesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorTrajectoriesActions.trajectoriesFetchRequested),
      mergeMap(({ sensorId }) =>
        this.trajectoriesApiService.getSensorTrajectories(sensorId).pipe(
          map(sensorTrajectories =>
            SensorTrajectoriesActions.trajectoriesFetched({
              sensorId,
              sensorTrajectories: mapTrajectoriesFromDto(sensorTrajectories),
            })
          ),
          catchApiError(false, error =>
            isNotFoundStatus(error)
              ? SensorTrajectoriesActions.trajectoriesNotFound({ sensorId })
              : SensorTrajectoriesActions.trajectoriesFetchFailed({ sensorId })
          )
        )
      )
    )
  );

  visualizeTrajectoryTrendEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorTrajectoriesActions.trajectoriesTrendVisualizeRequested),
      mergeMap(({ sensorId, trajectory, trendType }) =>
        this.trajectoriesApiService.getTrajectoryTrend(trajectory.id, trajectory.type, trendType).pipe(
          map(trajectoryTrend =>
            SensorGraphsActions.addCurve({
              sensorId,
              curveId: trendCompositeKey(trajectory, trendType),
              curve: getTrajectoryTrendCurve(trajectoryTrend, trajectory, trendType),
            })
          ),
          catchApiError(false, () => SensorTrajectoriesActions.trajectoriesTrendFetchFailed())
        )
      )
    )
  );

  visualizeHarmonicTrajectoryTrendEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorTrajectoriesActions.harmonicTrendVisualizationRequested),
      mergeMap(({ sensorId, trajectory, trendType }) =>
        this.trajectoriesApiService
          .getHarmonicTrajectoryTrend(trajectory.id, trajectory.type, trajectory.rank, trendType)
          .pipe(
            map(trajectoryTrend =>
              SensorGraphsActions.addCurve({
                sensorId,
                curveId: harmonicTrendCompositeKey(trajectory, trendType),
                curve: getHarmonicTrajectoryTrendCurve(trajectoryTrend, trajectory, trendType),
              })
            ),
            catchApiError(false, () => SensorTrajectoriesActions.trajectoriesTrendFetchFailed())
          )
      )
    )
  );

  fetchTrajectoryExportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorTrajectoriesActions.trajectoryExportRequested),
      mergeMap(({ trajectory }) =>
        this.trajectoriesApiService.exportTrajectory(trajectory).pipe(
          map(csvText => SensorTrajectoriesActions.trajectoryExportFetched({ csvText, trajectory })),
          catchApiError(false, () => SensorTrajectoriesActions.trajectoriesTrendFetchFailed())
        )
      )
    )
  );

  downloadTrajectoryExport$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SensorTrajectoriesActions.trajectoryExportFetched),
        tap(({ csvText, trajectory }) =>
          download(`${trajectory.name.replace(', ', ',').replace(' ', '_')}.csv`, csvText)
        )
      ),
    { dispatch: false }
  );
}
