import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { ComputationStatus } from '@shared/interfaces/processing-status';
import { recordFrom } from '@tools/utilities/record-utilities';

import { AStrionSignalsAllStatusDto, AStrionSignalsStatusMap } from './astrion-signals-status.interface';

export const string2Status = (s: string | undefined): ComputationStatus => {
  switch (s) {
    case 'Scheduled':
      return ComputationStatus.Scheduled;
    case 'Dirty':
      return ComputationStatus.Dirty;
    case 'InProgress':
      return ComputationStatus.InProgress;
    case 'Succeeded':
      return ComputationStatus.Succeeded;
    case 'NotComputed':
      return ComputationStatus.NotComputed;
    case 'Failed':
    case undefined:
      return ComputationStatus.Failed;
    default:
      return ComputationStatus.Unknown;
  }
};

const combineStatuses = (statuses: ComputationStatus[]): ComputationStatus =>
  statuses.reduce((prev, cur) => Math.min(prev, cur), ComputationStatus.Succeeded);

export const dto2Map = (dtos: AStrionSignalsAllStatusDto): AStrionSignalsStatusMap => {
  const dvStatuses = dtos.dataValidationStatus?.statuses ?? [];
  const saStatuses = dtos.spectralAnalysisStatus?.statuses ?? [];
  const validationFlags = dtos.validationFlags ?? [];

  const ids: AStrionSignalId[] = [
    ...new Set<AStrionSignalId>([
      ...dvStatuses.map(s => s.signalId),
      ...saStatuses.map(s => s.signalId),
      ...validationFlags.map(s => s.signalId),
    ]),
  ];

  return recordFrom(
    ids.map((id: AStrionSignalId) => [
      id as AStrionSignalId,
      {
        computationStatus: combineStatuses([
          string2Status(dvStatuses.find(s => s.signalId == id)?.computationStatus),
          string2Status(saStatuses.find(s => s.signalId == id)?.computationStatus),
        ]),
        computationMessage:
          dvStatuses.find(s => s.signalId == id)?.computationMessage ||
          saStatuses.find(s => s.signalId == id)?.computationMessage,
        validationFlags: validationFlags.find(s => s.signalId == id),
      },
    ])
  );
};
