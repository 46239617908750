import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './sensors.reducer';
import { name } from './sensors.state';
import { selectRouteParam } from '@store/router.selectors';
import { SensorId } from '../interfaces/sensor.interface';

export const sensorsFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectSensors }) => {
    const selectCurrentSensorId = createSelector(selectRouteParam('id'), id => {
      return id as unknown as SensorId | undefined;
    });
    const selectCurrentSensor = createSelector(selectCurrentSensorId, selectSensors, (id, sensors) =>
      sensors.find(s => s.id === id)
    );

    const selectSensor = (sensorId: SensorId) =>
      createSelector(selectSensors, sensors => sensors.find(s => s.id == sensorId));

    return {
      selectSensor,
      selectCurrentSensorId,
      selectCurrentSensor,
    };
  },
});
