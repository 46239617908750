import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './equipments.reducer';
import { name } from './equipments.state';
import { LoadingState } from '@shared/interfaces/loading-state';
import { selectRouteParam } from '@store/router.selectors';
import { EquipmentId } from '../interfaces/equipment.interface';

export const equipmentsFeature = createFeature({
  name,
  reducer,
  extraSelectors: ({ selectEquipments, selectLoadingState }) => {
    const selectCurrentEquipmentId = createSelector(selectRouteParam('id'), id => {
      return id as unknown as EquipmentId | undefined;
    });
    const selectCurrentEquipment = createSelector(selectCurrentEquipmentId, selectEquipments, (id, equipments) =>
      equipments.find(s => s.id === id)
    );

    const selectEquipment = (equipmentId: EquipmentId) =>
      createSelector(selectEquipments, equipments => equipments.find(s => s.id == equipmentId));

    return {
      selectIsLoading: createSelector(selectLoadingState, loadingState => loadingState === LoadingState.Loading),
      selectInitialized: createSelector(selectLoadingState, loadingState => loadingState === LoadingState.Loaded),
      selectEquipment,
      selectCurrentEquipmentId,
      selectCurrentEquipment,
    };
  },
});
