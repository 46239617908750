@let _status = status();

@if (_status.progressRatio < 0.0) {
  <mat-icon>pending</mat-icon>
} @else {
  @if (_status.progressRatio >= 1.0 && _status.ok !== undefined) {
    @if (_status.ok) {
      <mat-icon class="text-success">check_circle</mat-icon>
    } @else {
      <mat-icon class="text-error">error</mat-icon>
    }
  } @else {
    <mat-progress-spinner [diameter]="24" mode="determinate" [value]="100 * _status.progressRatio" />
  }
}
