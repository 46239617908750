import { GraphCurve } from '@astrion-webtools/graph';

import { TrajectoryTrendDto, TrendType } from '../interfaces/trajectory-trend.interface';
import { HarmonicTrajectory, Trajectory } from '../interfaces/trajectory.interface';

const trendDisplayName = (trendType: TrendType) => {
  switch (trendType) {
    case TrendType.Frequency:
    case TrendType.HarmonicFrequencies:
      return 'Frequency';
    case TrendType.Energy:
    case TrendType.HarmonicEnergies:
      return 'Energy';
    case TrendType.HarmonicsCount:
      return 'Harmonics count';
    case TrendType.AverageHarmonicEnergy:
      return 'Average harmonic energy';
    case TrendType.Regularity:
      return 'Regularity';
    case TrendType.THD:
      return 'THD';
    case TrendType.HarmonicSidebandsSeriesCount:
      return "Modulation families' count";
    case TrendType.HarmonicSidebandsSeriesPercentage:
      return "Modulation families' percentage";
  }
};

export function getTrajectoryTrendCurve(
  trajectoryTrend: TrajectoryTrendDto,
  trajectory: Trajectory,
  trendType: TrendType
): GraphCurve {
  return {
    id: trajectoryTrend.id,
    displayName: `${trajectory.name} ${trendDisplayName(trendType)}`,
    data: {
      indexedDb: {
        id: trajectoryTrend.interlacedValues,
        valuesField: 'interlacedValues',
      },
    },
  };
}

export function getHarmonicTrajectoryTrendCurve(
  trajectoryTrend: TrajectoryTrendDto,
  trajectory: HarmonicTrajectory,
  trendType: TrendType
): GraphCurve {
  return {
    id: trajectoryTrend.id,
    displayName: `${trajectory.name} ${trendDisplayName(trendType)} [${trajectory.rank}]`,
    data: {
      indexedDb: {
        id: trajectoryTrend.interlacedValues,
        valuesField: 'interlacedValues',
      },
    },
  };
}

const trendTypeNameMap = new Map<TrendType, string>([
  [TrendType.Frequency, 'frequency'],
  [TrendType.Energy, 'energy'],
  [TrendType.HarmonicFrequencies, 'frequency'],
  [TrendType.HarmonicEnergies, 'energy'],
  [TrendType.HarmonicsCount, 'harmonics-count'],
  [TrendType.AverageHarmonicEnergy, 'average-harmonic-energy'],
  [TrendType.Regularity, 'regularity'],
  [TrendType.THD, 'total-harmonic-distortion'],
  [TrendType.HarmonicSidebandsSeriesCount, 'harmonic-sidebands-series-count'],
  [TrendType.HarmonicSidebandsSeriesPercentage, 'harmonic-sidebands-series-percentage'],
]);

export const trendTypeName = (type: TrendType): string => trendTypeNameMap.get(type)!;
