import { createFeature, createSelector } from '@ngrx/store';

import { reducer } from './signal-overview.reducer';
import { featureName } from './signal-overview.state';
import { DataBytes } from '@shared/interfaces/data-bytes';
import { SignalContent } from '../interface/signal-content';
import { SignalMetadata } from '../interface/signal-metadata';

export const signalOverviewFeature = createFeature({
  name: featureName,
  reducer,
  extraSelectors: ({ selectSignalData }) => ({
    selectSignalBytes: createSelector(
      selectSignalData,
      (signalData: SignalContent | undefined): DataBytes | undefined => signalData?.content
    ),
    selectSignalMetadata: createSelector(
      selectSignalData,
      (signalData: SignalContent | undefined): SignalMetadata | undefined => signalData?.metadata
    ),
  }),
});
