import { FilesUploadJob } from '@features/files-upload/interface/files-upload.interface';

export const name = 'files-upload';

export interface FilesUploadState {
  jobs: FilesUploadJob[];
  currentJobIdx: number | null;
  dialogOpen: boolean;
  cancelUpload: boolean;
}

export const FILES_UPLOAD_INITIAL_STATE: FilesUploadState = {
  jobs: [],
  currentJobIdx: null,
  dialogOpen: false,
  cancelUpload: false,
};
