import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardFunction } from '@features/auth/auth.guard';
import { AUTH_ROUTES } from '@features/auth/auth.routes';
import { projectGuardFunction } from '@features/auth/project.guard';
import { projectChangeInUrlGuardFunction } from '@features/auth/projectChangeInUrl.guard';

const APP_ROUTES: Routes = [
  {
    path: 'auth',
    children: AUTH_ROUTES,
  },
  {
    path: '',
    canActivate: [authGuardFunction, projectChangeInUrlGuardFunction, projectGuardFunction],
    pathMatch: 'prefix',
    children: [
      {
        path: ':projectName/signals/report',
        loadChildren: () =>
          import('@features/signal-report/signal-report.routes').then(mod => mod.SIGNAL_REPORT_ROUTES),
      },
      {
        path: ':projectName/operation',
        loadComponent: () =>
          import('@features/equipments/views/operation-view/operation-view.component').then(
            mod => mod.OperationViewComponent
          ),
      },
      {
        path: 'operation',
        loadComponent: () =>
          import('./features/equipments/views/operation-view/operation-view.component').then(
            mod => mod.OperationViewComponent
          ),
      },
      {
        path: ':projectName/equipment',
        loadChildren: () => import('@features/equipments/equipments.routes').then(mod => mod.EQUIPMENTS_ROUTES),
      },
      {
        path: ':projectName/sensor',
        loadChildren: () => import('@features/sensors/sensors.routes').then(mod => mod.SENSORS_ROUTES),
      },
      {
        path: '**',
        redirectTo: 'operation',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
