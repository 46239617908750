import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { ComputationStatus } from '@shared/interfaces/processing-status';

import { HarmonicSeriesTrajectory, PeakTrajectory } from '../interfaces/trajectory.interface';

export const name = 'sensor-Trajectories';

export interface SensorTrajectoriesState {
  sensorId: SensorId | null;
  computationStatus: ComputationStatus;
  computationMessage: string | undefined;
  nextComputationDate: Date | null;
  peakTrajectories: PeakTrajectory[] | null;
  harmonicSeriesTrajectories: HarmonicSeriesTrajectory[] | null;
}

export const SENSOR_TRAJECTORIES_INITIAL_STATE: SensorTrajectoriesState = {
  sensorId: null,
  computationStatus: ComputationStatus.Unknown,
  computationMessage: undefined,
  nextComputationDate: null,
  peakTrajectories: null,
  harmonicSeriesTrajectories: null,
};
