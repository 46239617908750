import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ExtraMatDialogService extends MatDialog {
  override open<T, D = unknown, R = unknown>(
    component: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    const element = this._unfocus();
    const ref = super.open(component, config);
    if (config?.restoreFocus ?? false) {
      firstValueFrom(ref.afterClosed()).then(() => element.focus());
    }
    return ref;
  }

  private _unfocus() {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
    return activeElement;
  }
}
