import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { FilesUploadService } from '@features/files-upload/shared/services/files-upload.service';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-upload-background-button',
  imports: [MaterialModule],
  templateUrl: './upload-background-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadBackgroundButtonComponent {
  constructor(private service: FilesUploadService) {}

  ratio = this.service.globalProgressRatio;
  isOver = this.service.uploadOver;
  hasErrors = this.service.hasErrors;

  enabled = computed(() => !this.isOver() || this.hasErrors());
  badge = computed<string | undefined>(() => (this.hasErrors() ? '!' : undefined));

  openDialog() {
    this.service.openUploadDialog();
  }
}
