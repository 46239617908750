import { Guid } from '@astrion-webtools/graph';
import { EquipmentId } from '@features/equipments/shared/interfaces/equipment.interface';

interface SensorProperties {
  name: string;
  model: string | null;
  component: string | null;
  collectPoint: string | null;
  orientation: string | null;
  pulseSensorId: SensorId | null;
}

interface SensorPayloadProperties extends SensorProperties {
  equipmentId: EquipmentId;
  sensorType?: string | undefined;
}

export enum SensorType {
  Unknown = 0,
  Vibration,
  Pulse,
  Miscellaneous = 255,
}

const string2Type = (s: string | undefined): SensorType => {
  switch (s) {
    case 'Vibration':
      return SensorType.Vibration;
    case 'Pulse':
      return SensorType.Pulse;
    case 'Miscellaneous':
      return SensorType.Miscellaneous;
    default:
      return SensorType.Unknown;
  }
};

export const type2String = (type: SensorType): string => {
  switch (type) {
    case SensorType.Vibration:
      return 'Vibration';
    case SensorType.Pulse:
      return 'Pulse';
    case SensorType.Miscellaneous:
      return 'Miscellaneous';
    default:
      return 'Unknown';
  }
};

export type SensorId = Guid;

export interface SensorDto extends SensorPayloadProperties {
  id: SensorId;
  isComputableSensor: boolean;
  signalsCount: number;
}

export interface SensorPayload extends SensorPayloadProperties {
  id?: SensorId;
}

export interface Sensor extends SensorProperties {
  id: SensorId;
  equipmentId: EquipmentId;
  sensorType: SensorType;
  isComputableSensor: boolean;
  pulseSensorId: SensorId | null;
  signalsCount: number;
}

export interface SensorSummary {
  id: SensorId;
  sensorType: SensorType;
  isComputableSensor: boolean;
  name: string;
  signalsCount: number;
}

export const mapSensorFromDto = (dto: SensorDto): Sensor => ({
  ...dto,
  sensorType: string2Type(dto.sensorType),
});
