import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createReducer, on } from '@ngrx/store';
import { loaded, LoadingState } from '@shared/interfaces/loading-state';

import { Cycle, Cycles, defaultCyclesWithLoadingState } from '../interface/cycles';
import { defaultFusionWithLoadingState, Fusion } from '../interface/fusion';
import { defaultHarmonicSeriesWithLoadingState, HarmonicSeries } from '../interface/harmonic-series';
import { SpectralAnalysis } from '../interface/spectral-analysis';
import { SpectralAnalysisActions } from './spectral-analysis.actions';
import { SPECTRAL_ANALYSIS_INITIAL_STATE, SpectralAnalysisState } from './spectral-analysis.state';

const recreateCyclesData = (originalData: Cycle[], cycleIndex: number, newCycleData: Cycle) => {
  const newData = [...originalData.slice(0, cycleIndex), newCycleData, ...originalData.slice(cycleIndex + 1)];

  return newData;
};

export const reducer = createReducer(
  SPECTRAL_ANALYSIS_INITIAL_STATE,
  on(
    SpectralAnalysisActions.spectralAnalysisReset,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId | null }): SpectralAnalysisState => ({
      ...SPECTRAL_ANALYSIS_INITIAL_STATE,
      signalId,
    })
  ),
  on(
    SpectralAnalysisActions.spectralAnalysisFetchRequested,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...SPECTRAL_ANALYSIS_INITIAL_STATE,
        signalId,
        loadingState: LoadingState.Loading,
      };
    }
  ),
  on(
    SpectralAnalysisActions.spectralAnalysisFetched,
    (
      state: SpectralAnalysisState,
      { signalId, spectralAnalysis }: { signalId: AStrionSignalId; spectralAnalysis: SpectralAnalysis }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...state,
        ...spectralAnalysis,
      };
    }
  ),
  on(
    SpectralAnalysisActions.spectralAnalysisNotFound,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...SPECTRAL_ANALYSIS_INITIAL_STATE,
        loadingState: LoadingState.NotFound,
      };
    }
  ),
  on(
    SpectralAnalysisActions.spectralAnalysisFetchFailed,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId) {
        return state;
      }

      return {
        ...SPECTRAL_ANALYSIS_INITIAL_STATE,
        loadingState: LoadingState.Error,
      };
    }
  ),
  on(
    SpectralAnalysisActions.cyclesFetchRequested,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    SpectralAnalysisActions.cyclesFetched,
    (
      state: SpectralAnalysisState,
      { signalId, cycles }: { signalId: AStrionSignalId; cycles: Cycles }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles,
      };
    }
  ),
  on(
    SpectralAnalysisActions.cyclesNotFound,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    SpectralAnalysisActions.cyclesFetchFailed,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        cycles: defaultCyclesWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleSpectrumBytesFetchRequested,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.Loading,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleSpectrumBytesFetched,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex, dataId }: { signalId: AStrionSignalId; cycleIndex: number; dataId: IndexedDbRowId }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.Loaded,
            data: dataId,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleSpectrumBytesNotFound,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.NotFound,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleSpectrumBytesFetchFailed,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        spectrum: {
          ...indexedCycleData.spectrum,
          amplitudes: {
            ...indexedCycleData.spectrum.amplitudes,
            loadingState: LoadingState.Error,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleNoiseBytesFetchRequested,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.Loading,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleNoiseBytesFetched,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex, dataId }: { signalId: AStrionSignalId; cycleIndex: number; dataId: IndexedDbRowId }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.Loaded,
            data: dataId,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleNoiseBytesNotFound,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.NotFound,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.cycleNoiseBytesFetchFailed,
    (
      state: SpectralAnalysisState,
      { signalId, cycleIndex }: { signalId: AStrionSignalId; cycleIndex: number }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.cycles)) {
        return state;
      }

      if (cycleIndex >= state.cycles.data!.length) {
        return state;
      }

      const cyclesData = state.cycles.data!;
      const indexedCycleData = cyclesData[cycleIndex];
      const newCycleData = {
        ...indexedCycleData,
        noise: {
          ...indexedCycleData.noise,
          amplitudes: {
            ...indexedCycleData.noise.amplitudes,
            loadingState: LoadingState.Error,
            data: undefined,
          },
        },
      };

      return {
        ...state,
        cycles: {
          ...state.cycles,
          data: recreateCyclesData(cyclesData, cycleIndex, newCycleData),
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionFetchRequested,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionFetched,
    (
      state: SpectralAnalysisState,
      { signalId, fusion }: { signalId: AStrionSignalId; fusion: Fusion }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion,
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNotFound,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionFetchFailed,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        fusion: defaultFusionWithLoadingState(LoadingState.Error),
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMinBytesFetchRequested,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.Loading,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMinBytesFetched,
    (
      state: SpectralAnalysisState,
      { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.Loaded,
                data: dataId,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMinBytesNotFound,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.NotFound,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMinBytesFetchFailed,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              minAmplitudes: {
                ...state.fusion.data!.noiseArea.minAmplitudes,
                loadingState: LoadingState.Error,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMaxBytesFetchRequested,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.Loading,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMaxBytesFetched,
    (
      state: SpectralAnalysisState,
      { signalId, dataId }: { signalId: AStrionSignalId; dataId: IndexedDbRowId }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.Loaded,
                data: dataId,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMaxBytesNotFound,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.NotFound,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.fusionNoiseMaxBytesFetchFailed,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state) || !loaded(state.fusion)) {
        return state;
      }

      return {
        ...state,
        fusion: {
          ...state.fusion,
          data: {
            ...state.fusion.data!,
            noiseArea: {
              ...state.fusion.data!.noiseArea,
              maxAmplitudes: {
                ...state.fusion.data!.noiseArea.maxAmplitudes,
                loadingState: LoadingState.Error,
                data: undefined,
              },
            },
          },
        },
      };
    }
  ),
  on(
    SpectralAnalysisActions.harmonicSeriesFetchRequested,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries: defaultHarmonicSeriesWithLoadingState(LoadingState.Loading),
      };
    }
  ),
  on(
    SpectralAnalysisActions.harmonicSeriesFetched,
    (
      state: SpectralAnalysisState,
      { signalId, harmonicSeries }: { signalId: AStrionSignalId; harmonicSeries: HarmonicSeries }
    ): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries,
      };
    }
  ),
  on(
    SpectralAnalysisActions.harmonicSeriesNotFound,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries: defaultHarmonicSeriesWithLoadingState(LoadingState.NotFound),
      };
    }
  ),
  on(
    SpectralAnalysisActions.harmonicSeriesFetchFailed,
    (state: SpectralAnalysisState, { signalId }: { signalId: AStrionSignalId }): SpectralAnalysisState => {
      if (signalId !== state.signalId || !loaded(state)) {
        return state;
      }

      return {
        ...state,
        harmonicSeries: defaultHarmonicSeriesWithLoadingState(LoadingState.Error),
      };
    }
  )
);
