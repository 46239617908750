<h2 mat-dialog-title>Files uploading</h2>
<mat-dialog-content (dragenter)="onDragStart()" class="relative m-2 flex-grow">
  <mat-nav-list class="absolute inset-0 overflow-y-auto">
    @for (file of selectedFiles(); track $index; let index = $index) {
      <mat-list-item>
        <mat-icon [ngStyle]="{ color: fileInfo.getColor(file) }" matListItemIcon>{{ fileInfo.getIcon(file) }}</mat-icon>
        <span matListItemTitle>{{ file.name }}</span>
        <button matListItemMeta mat-icon-button (click)="removeFile(index)">
          <mat-icon class="error-icon">cancel</mat-icon>
        </button>
      </mat-list-item>
    }
  </mat-nav-list>
  <app-drag-and-drop
    class="absolute inset-0"
    [ngStyle]="{ 'z-index': showDragAndDrop() ? 1 : -1, visibility: showDragAndDrop() ? 'visible' : 'hidden' }"
    (dragStopped)="onDragEnd()"
    (filesDropped)="addFiles($event)" />
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancel()"><mat-icon>close</mat-icon>Cancel</button>
  <input hidden type="file" multiple id="files" #filesInput (change)="onFileSelected($event)" />
  <span class="grow"></span>
  <button mat-stroked-button class="secondary-button" (click)="filesInput.click()" #choiceButton>
    <mat-icon>note_add</mat-icon>Choose files
  </button>
  <button mat-raised-button class="primary-button" [disabled]="isEmpty()" (click)="uploadFiles()" #uploadButton>
    <mat-icon>upload_file</mat-icon>Upload
  </button>
</mat-dialog-actions>
