import { NgModule } from '@angular/core';
import { RealtimeModule } from '@features/realtime/realtime.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SpectralAnalysisRealtimeRegistrationService } from './shared/realtime/spectral-analysis-realtime-service';
import { SpectralAnalysisSensorApiService } from './shared/services/spectral-analysis-sensor-api.service';
import { SpectralAnalysisEffects } from './shared/store/spectral-analysis.effects';
import { spectralAnalysisFeature } from './shared/store/spectral-analysis.feature';

@NgModule({
  declarations: [],
  providers: [SpectralAnalysisSensorApiService],
  imports: [
    StoreModule.forFeature(spectralAnalysisFeature),
    EffectsModule.forFeature([SpectralAnalysisEffects]),
    RealtimeModule.forFeature(SpectralAnalysisRealtimeRegistrationService),
  ],
})
export class SpectralAnalysisModule {}
