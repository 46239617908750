import { Guid } from '@app-types/guid.type';

export interface TrajectoryLabelDto {
  id: Guid;
  name: string;
}

export interface TrajectoryDtoBase {
  id: Guid;
  frequency: number;
  detectionPercentage: number;
  labels: TrajectoryLabelDto[];
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
}

export type PeakTrajectoryDto = TrajectoryDtoBase;

export interface HarmonicTrajectoryDto {
  id: Guid;
  rank: number;
}

export interface HarmonicSeriesTrajectoryDto extends TrajectoryDtoBase {
  harmonicsCountTrendMiniatureContentPath: string;
  averageHarmonicEnergyTrendMiniatureContentPath: string;
  regularityTrendMiniatureContentPath: string;
  thdTrendMiniatureContentPath: string;
  harmonicSidebandsSeriesCountTrendMiniatureContentPath: string;
  harmonicSidebandsSeriesPercentageTrendMiniatureContentPath: string;
  compoundHarmonicFrequencyTrendsMiniatureContentPath: string;
  compoundHarmonicEnergyTrendsMiniatureContentPath: string;
  harmonicTrajectories: HarmonicTrajectoryDto[];
}

export interface SensorTrajectoriesDto {
  computationStatus: string;
  computationMessage: string | undefined;
  nextComputationDate: Date | null;
  peakTrajectories: PeakTrajectoryDto[] | null;
  harmonicSeriesTrajectories: HarmonicSeriesTrajectoryDto[] | null;
}

export enum TrajectoryType {
  Unknown = 0,
  PeakTrajectory = 1,
  HarmonicSeriesTrajectory = 2,
  HarmonicTrajectory = 3,
  None = 255,
}

export interface TrajectoryBase {
  id: Guid;
  type: TrajectoryType;
  name: string;
  frequency: number;
  detectionPercentage: number;
  isLabeled: boolean;
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
}

export interface PeakTrajectory extends TrajectoryBase {
  type: TrajectoryType.PeakTrajectory;
}

export interface HarmonicTrajectory extends TrajectoryBase {
  type: TrajectoryType.HarmonicTrajectory;
  rank: number;
}

export interface HarmonicSeriesTrajectory extends TrajectoryBase {
  type: TrajectoryType.HarmonicSeriesTrajectory;
  harmonicsCountTrendMiniatureContentPath: string;
  averageHarmonicEnergyTrendMiniatureContentPath: string;
  regularityTrendMiniatureContentPath: string;
  thdTrendMiniatureContentPath: string;
  harmonicSidebandsSeriesCountTrendMiniatureContentPath: string;
  harmonicSidebandsSeriesPercentageTrendMiniatureContentPath: string;
  harmonicFrequenciesMiniatureContentPath: string;
  harmonicEnergiesMiniatureContentPath: string;
  harmonicTrajectories: HarmonicTrajectory[];
}

export type Trajectory = PeakTrajectory | HarmonicSeriesTrajectory;

export interface TrajectoriesList {
  peakTrajectories: PeakTrajectory[] | null;
  harmonicSeriesTrajectories: HarmonicSeriesTrajectory[] | null;
}

export interface SensorTrajectories extends TrajectoriesList {
  computationStatus: string;
  computationMessage: string | undefined;
  nextComputationDate: Date | null;
}

const trajectoryTypeNameMap = new Map<TrajectoryType, string>([
  [TrajectoryType.PeakTrajectory, 'peak-trajectory'],
  [TrajectoryType.HarmonicSeriesTrajectory, 'harmonic-series-trajectory'],
  [TrajectoryType.HarmonicTrajectory, 'harmonic-trajectory'],
]);

export const trajectoryTypeName = (type: TrajectoryType): string => trajectoryTypeNameMap.get(type)!;

export function isPeakTrajectory(trajectory: Trajectory): trajectory is PeakTrajectory {
  return trajectory.type === TrajectoryType.PeakTrajectory;
}

export function isHarmonicSeriesTrajectory(trajectory: Trajectory): trajectory is HarmonicSeriesTrajectory {
  return trajectory.type === TrajectoryType.HarmonicSeriesTrajectory;
}
