import { IndexedDbRowId } from '@astrion-webtools/graph';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Cycles } from '../interface/cycles';
import { Fusion } from '../interface/fusion';
import { HarmonicSeries } from '../interface/harmonic-series';
import { SpectralAnalysis } from '../interface/spectral-analysis';

export const SpectralAnalysisActions = createActionGroup({
  source: 'SpectralAnalysis',
  events: {
    spectralAnalysisReset: props<{ signalId: AStrionSignalId | null }>(),

    spectralAnalysisFetchRequested: props<{ signalId: AStrionSignalId }>(),
    spectralAnalysisFetched: props<{ signalId: AStrionSignalId; spectralAnalysis: SpectralAnalysis }>(),
    spectralAnalysisNotFound: props<{ signalId: AStrionSignalId }>(),
    spectralAnalysisFetchFailed: props<{ signalId: AStrionSignalId }>(),

    cyclesFetchRequested: props<{ signalId: AStrionSignalId; samplingFrequency: number }>(),
    cyclesFetched: props<{ signalId: AStrionSignalId; cycles: Cycles }>(),
    cyclesNotFound: props<{ signalId: AStrionSignalId }>(),
    cyclesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    cycleBytesFetchEmissionRequested: props<{ signalId: AStrionSignalId; cycleIndex: number; cycles: Cycles }>(),
    invalidCycleIndexBytesFetchEmissionRequested: props<{ signalId: AStrionSignalId; cycleIndex: number }>(),
    cycleBytesFetchRejected: emptyProps(),

    cycleSpectrumBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      cycleIndex: number;
      contentPath: string;
      freqMin: number;
      freqMax: number;
    }>(),
    cycleSpectrumBytesFetched: props<{ signalId: AStrionSignalId; cycleIndex: number; dataId: IndexedDbRowId }>(),
    cycleSpectrumBytesNotFound: props<{ signalId: AStrionSignalId; cycleIndex: number }>(),
    cycleSpectrumBytesFetchFailed: props<{ signalId: AStrionSignalId; cycleIndex: number }>(),

    cycleNoiseBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      cycleIndex: number;
      contentPath: string;
      freqMin: number;
      freqMax: number;
    }>(),
    cycleNoiseBytesFetched: props<{ signalId: AStrionSignalId; cycleIndex: number; dataId: IndexedDbRowId }>(),
    cycleNoiseBytesNotFound: props<{ signalId: AStrionSignalId; cycleIndex: number }>(),
    cycleNoiseBytesFetchFailed: props<{ signalId: AStrionSignalId; cycleIndex: number }>(),

    fusionFetchRequested: props<{ signalId: AStrionSignalId }>(),
    fusionFetched: props<{ signalId: AStrionSignalId; fusion: Fusion }>(),
    fusionNotFound: props<{ signalId: AStrionSignalId }>(),
    fusionFetchFailed: props<{ signalId: AStrionSignalId }>(),

    fusionBytesFetchRejected: emptyProps(),

    fusionNoiseMinBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      contentPath: string;
      freqMin: number;
      freqMax: number;
    }>(),
    fusionNoiseMinBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    fusionNoiseMinBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    fusionNoiseMinBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    fusionNoiseMaxBytesFetchRequested: props<{
      signalId: AStrionSignalId;
      contentPath: string;
      freqMin: number;
      freqMax: number;
    }>(),
    fusionNoiseMaxBytesFetched: props<{ signalId: AStrionSignalId; dataId: IndexedDbRowId }>(),
    fusionNoiseMaxBytesNotFound: props<{ signalId: AStrionSignalId }>(),
    fusionNoiseMaxBytesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    harmonicSeriesFetchRequested: props<{ signalId: AStrionSignalId }>(),
    harmonicSeriesFetched: props<{ signalId: AStrionSignalId; harmonicSeries: HarmonicSeries }>(),
    harmonicSeriesNotFound: props<{ signalId: AStrionSignalId }>(),
    harmonicSeriesFetchFailed: props<{ signalId: AStrionSignalId }>(),

    noAction: emptyProps(),
  },
});
